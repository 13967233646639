<template>
  <div>
    <el-upload class="upload-demo" drag :before-upload="beforeUpload" :http-request="upload" action :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
      <!-- <i class="el-icon-upload" style="width:30px;height:30px"></i> -->
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <template #tip>
        <div class="el-upload__tip">只能上传 apk 文件</div>
      </template>
    </el-upload>
    <el-button type="primary" @click="uploadFile">点击上传</el-button>
    <!-- <input type="file" @change="uploadFile" /> -->
  </div>
</template>
 
<script setup>
import { ref, getCurrentInstance } from 'vue';
import OSS from 'ali-oss';
import { dictIcon } from '../../../utils/ziyuan.js'
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { Files } from '@element-plus/icons';
const client = ref(null);
const fileList = ref([]);
const files = ref({});
const { proxy } = getCurrentInstance();
let loading;
// 初始化OSS客户端
async function initUpload(data) {
  client.value = new OSS({
    region: 'oss-cn-shanghai',
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    // accessKeyId: dictIcon.a.slice(20),
    // accessKeySecret: dictIcon.b.slice(20),
    // 从STS服务获取的安全令牌（SecurityToken）。
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: data.securityToken,
    // 填写Bucket名称，例如examplebucket。
    bucket: "ziben-oss",
  });
}

// 上传文件
async function uploadFile() {
  //   const file = event.target.files[0];
  const flag = Object.keys(files.value).length
  if (flag === 0) {
    ElMessage({
      message: "请上传文件",
      type: "error",
    });
    return
  };

  try {
    await getStsToken();
    const name = 'cts-app/' + files.value.name
    startLoading()
    const result = await client.value.put(name, files.value);
    if (result.name) {
      endLoading()
      ElMessage({
        message: "上传成功",
        type: "success",
      });
      fileList.value = []
      files.value = {}
    }
    console.log('File uploaded:', result);
  } catch (error) {
    console.error('Upload error:', error);
  }
}
function startLoading() {    //使用Element loading-start 方法
  loading = ElLoading.service({
    lock: true,
    text: '文件上传中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {    //使用Element loading-close 方法
  loading.close()
}
const upload = (param) => {
  files.value = param.file
}
const beforeUpload = (file) => {
  const apk = file.name.split(".")
  const isApk = apk[apk.length - 1] === "apk"
  if (!isApk) {
    ElMessage({
      message: "只能上传apk文件",
      type: "error",
    });
  }
  return isApk;
};
const handleRemove = (file, fileList) => {
  files.value = {}
};
const handlePreview = (file) => {
};
const handleExceed = (files) => {
};
const handleUpload = (formData) => {
}
const beforeRemove = (file, fileList) => {
}

const getStsToken = () => {
  proxy.$get("/cts/back/stsToken/getStsToken")
    .then((res) => {
      if (res.resultState === "200") {
        initUpload(res.data);
      }
    })
    .catch((err) => { });
}
// 在组件被创建时初始化客户端

getStsToken()
</script>